import React from 'react';
import { Form } from 'antd';

export const render = Component => ({className, input, meta, children, hasFeedback, selectField, dateField, label, labelAddon, error, checked,  ...rest }) => {
  
  const checkedValue = {};
  const hasError = error || (meta && meta.touched && meta.invalid);

  return (
    <Form.Item
      label={
        <div className="form-label">
          <label>{label}</label>
          {labelAddon && <label className="addon">{labelAddon}</label>}
        </div>
      }
      colon={label && label === ' ' ? false : true}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && (meta ? meta.error : error)}
      extra={rest.extra}
      className={className ? className : ''}
    >
      {rest.type && rest.type === 'select'
        ? <Component value={rest.field && rest.field.value} {...input} {...checkedValue} {...rest} children={children} />
        : rest.type && rest.type === 'date'
          ? <Component {...input} {...checkedValue} {...rest} value={rest.field.value} children={children} />
          : <Component {...input} {...checkedValue} {...rest.field} {...rest} children={children} />
      }
    </Form.Item>
  )
}


export const renderUpload = Component => ({ input, meta, children, hasFeedback, label, ...rest }) => {
  const hasError = meta.touched && meta.invalid;
  const fileList = [];
  if (input.value != null && input.value !== '') {
    fileList.push({
      uid: '-1',
      name: rest.filename || '',
      status: 'done',
      url: input.value,
    });
  }

  const formItemLayout = rest.formItemLayout || {}
  return (
    <Form.Item
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
      {...formItemLayout}
    >
      <Component
        {...rest}
        fileList={fileList}
        onRemove={() => { input.onChange(null) }}
        onSuccess={(file) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const dataURL = e.target.result;
            if (rest.size) {
              resizedataURL(dataURL, rest.size.width, rest.size.height)
                .then(data => input.onChange(data));
            } else {
              input.onChange(dataURL);
            }
          }

          reader.readAsDataURL(file);

        }}
        customRequest={({ data, file, filename, onSuccess }) => {
          onSuccess(file, filename);
        }}
      >
        {children ? children : fileList.length >= 1 ? null :
          (<div>
            <div className="ant-upload-text">Upload</div>
          </div>)
        }
      </Component>
    </Form.Item>
  )
}

function resizedataURL(datas) {
  return new Promise((resolve) => {
    const img = document.createElement('img');

    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const oWidth = img.width;
      const oHeight = img.height;
      let nWidth = 300;
      let nHeight = 300;
      let x = 0;
      let y = 0;

      canvas.width = nWidth;
      canvas.height = nHeight;

      if (oWidth > oHeight) {
        nWidth = oWidth * (nHeight / oHeight);
        x = (nHeight - nWidth) / 2;
      } else {
        nHeight = oHeight * (nWidth / oWidth);
        y = (nWidth - nHeight) / 2;
      }
      ctx.drawImage(this, x, y, nWidth, nHeight);

      const dataURI = canvas.toDataURL();
      resolve(dataURI);
    };
    img.src = datas;
  })
}

export const required = (value, t) => (value || value === false) 
  ? undefined 
  : t ?  t('Required Field') : 'Required Field'

export const password = (value, t) => value && value.length > 7 && /\d/.test(value) ? undefined : t('Password does not meet the requirements')

export const confirmPassword = (value, password, t) => value === password
  ? undefined
  : t('Password do not match')

export const validateRequiredPositivePrice = (value, t) => {

  //!/^(\-)?[0-9]+(\.[0-9]{1,2})?$/.test(value)
  if(value=== undefined || value=== 0)return t ?  t('Required Field') : 'Required Field';

  if(!value) return t ?  t('Invalid Input') : 'Invalid Input';

  return Number(value) < 0 ? t ?  t('Invalid Input') : 'Invalid Input': undefined;
}

export const validateOptionalPositivePrice = (value, t) => {

  if(value=== undefined || value=== 0)return undefined;

  if(!value) return t ?  t('Invalid Input') : 'Invalid Input';

  return Number(value) < 0 ? t ?  t('Invalid Input') : 'Invalid Input': undefined;
}

export const validateEmail = (value, t) =>
  (!value || (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)))
    ? t ? t('Enter a valid email') : 'Please enter a valid email'
    : undefined

export const validatePhone = (value, t) => {

  if(!value)return t ?  t('Required Field') : 'Required Field';

  return !/^\d{9,10}$/.test(value)? 
  t ? t('Enter a valid phone no') : 'Enter a valid phone no'
  : undefined;
}